/* global
autosize
bsCustomFileInput
checkRedirect
initHtmlTextarea
updateHtmlContent
*/


// #############################################################################
// GLOBAL VARS

const $body = $("body");


// #############################################################################
// FOCUS

$body.betterFocus({
  selector: "a, [tabindex]",
});


// #############################################################################
// TOOLTIP

$("[data-toggle=tooltip]").tooltip();


// #############################################################################
// FORM

function initFormDefaults ($parent = $body) {
  // File
  // TODO: Selber schreiben!
  bsCustomFileInput.init();

  // Autosize
  // TODO: Selber schreiben!
  autosize($("textarea", $parent));

  // HTML TinyMCE
  initHtmlTextarea($parent);

  // Range
  $("[type=range]", $parent).formRange();

  // Rating
  $("[data-rating]", $parent).formRating();

  // Ajax upload
  $("[data-ajax-upload]", $parent).ajaxUpload({
    addExtraData: function ($upload, $form_data) {
      const $id_quantity = $("#id_makebidform-quantity");

      if ($id_quantity.length > 0) {
        $form_data.append("quantity", $id_quantity.val());
      }

      return $form_data;
    },
    onUploadCompleted: function ($upload, $data) {
      const $id_price = $("#id_makebidform-price");

      if ($id_price.length > 0 && $data.bid) {
        $id_price.val($data.bid);
      }

      updateHtmlContent($data);
    },
  });

  // File tree
  $("[data-file-tree]", $parent).formFileTree();

  // Form set

  $("[data-form-set]", $parent).formSet();
}

initFormDefaults();

$body.formNumberInput({
  selector: "[type=number]",
});

// Validation

$("[data-form]").formValidation({
  afterSubmit: function (request, $form, $data) {
    if ($data.submit === "success") {
      checkRedirect($data);
      updateHtmlContent($data);

      if ($data.toaster) {
        $body.toaster("updateToaster", $data.toaster);
      }
    }
  },
});

// Wizard

$("[data-form-wizard]").formWizard();


// #############################################################################
// AJAX MODAL

// TODO: selector auch bei anderen plugins übernehmen!

$body.ajaxModal({
  selector: "[data-modal-link]",
  beforeModalOpen: function ($modal, $data) {
    if ($data.submit === "error") {
      if ($data.toaster) {
        $body.toaster("updateToaster", $data.toaster);
      }
    }
  },
  onModalOpen: function ($modal) {
    $("[autofocus]", $modal).focus();
    $("[data-toggle=tooltip]", $modal).tooltip();

    initFormDefaults($modal);

    // Validation

    $("[data-form]", $modal).formValidation({
      afterSubmit: function (request, $form, $data) {
        if ($data.submit === "success") {
          $modal.modal("hide");

          checkRedirect($data);
          updateHtmlContent($data);

          if ($data.toaster) {
            $body.toaster("updateToaster", $data.toaster);
          }

          $("[data-table]").xDataTable("reload");
        }
      },
    });

    // Wizard

    $("[data-form-wizard]", $modal).formWizard();
  },
});


// #############################################################################
// DATA TABLE

$("[data-table]").xDataTable({
  options: {
    columns: [
      {
        data: "PLACEHOLDER_FIELDS",
      },
    ],
  },
  onInit: function ($table, $json) {
    // Custom code on init
  },
  onStateLoaded: function ($table, $data) {
    // Custom code on init
  },
  onDraw: function ($table) {
    // Custom code on redraw

    $("[data-toggle=tooltip]", $table).tooltip();
  },
  customizeCSV: function (csv) {
    // For customization read https://datatables.net/reference/button/csv

    return csv;
  },
});


// #############################################################################
// DOWNLOAD BLOB

$("[data-download]").downloadBlob({
  onDownloadStarted: function ($data) {
    $body.toaster("updateToaster", $data.toaster);
  },
});


// #############################################################################
// CLIPBOARD

$body.clipBoard({
  selector: "[data-clipboard]",
});


// #############################################################################
// TOASTER

$body.toaster({
  selector: "[data-toaster]",
});


// #############################################################################
// UPDATE CONTENT

function initUpdateContent () {
  $body.on("click", "[data-update]", function () {
    $.ajax({
      dataType: "json",
      type: "GET",
      url: this.href,
      success: function ($data) {
        console.log($data);
        updateHtmlContent($data);
      },
    });

    return false;
  });
}

initUpdateContent();


// #############################################################################
// AUTO UPDATE HTML CONTENT

$body.autoUpdateHtmlContent({
  selector: "[data-update-html-content]",
});


// #############################################################################
// PRODUCT TYPE

function initProductType () {
  $body.on("change", "[data-product-type-select]", function () {
    const $this = $(this);
    const value = $this.val();

    $this.parents("[data-form-wizard]").formWizard("reset");

    $("[data-product-type]").prop("hidden", true);
    $("[data-product-type=\"" + value + "\"]").prop("hidden", false);
  });
}

initProductType();


// #############################################################################
// GET BID INFOS

var bid_buttons = {}
$("[data-bid-id]").off("click");
$("[data-bid-id]").click(function () {
  const that = this;
  $(that).addClass("btn-loader");
  $.ajax({
    dataType: "json",
    type: "GET",
    url: $(that).attr("href"),
    success: function ($data) {
      if ($data.toaster) {
        $body.toaster("updateToaster", $data.toaster);
        $(that).removeClass("btn-loader");
      } else {
        const interval = setInterval(function () {
          check_bid_buttons();
        }, 1000);
        bid_buttons[$(that).attr("data-bid-id")] = interval;
      }
    },
    error: function (a, b, c) {
      console.log("error", a, b, c);
    },
  });
});

$("[data-bid-id].btn-loader").each(function () {
  const interval = setInterval(function () {
    check_bid_buttons();
  }, 1000);
  bid_buttons[$(this).attr("data-bid-id")] = interval;
});

function check_bid_buttons () {
  $("[data-bid-id].btn-loader").each(function () {
    const that = this;
    $.ajax({
      dataType: "json",
      type: "GET",
      url: "/client/bid/" + $(that).attr("data-bid-id") + "/get/info/status/",
      success: function ($data) {
        if ($data.result === "OK") {
          clearInterval(bid_buttons[$data.bid_id]);
          $(that).removeClass("btn-loader");
          $(that).parents("[data-bid-buttons]").html($data.template);
        }
      },
      error: function (a, b, c) {
        console.log("error", a, b, c);
      },
    });
  });
}
